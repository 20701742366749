<template>
    <div class="content-box">
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>资料理</el-breadcrumb-item>
                <el-breadcrumb-item>文档查询</el-breadcrumb-item>
            </el-breadcrumb>

            <el-form :inline="true" class="serach" ref="searchForm" >
                <div class="button-gutter">
                    <div class="name_left">
                        <el-form-item label="文件名称：">
                            <el-input v-model="searchData" placeholder="请输入文件名称"></el-input>
                        </el-form-item>
                    </div>
                    <div class="btn_right">
                        <el-form-item>
                            <el-button type="primary" @click="search()">查询</el-button>
                            <el-button style="margin-left: 15px" @click="reset('searchForm')">重置</el-button>
                        </el-form-item>
                    </div>
                </div>
            </el-form>

            <el-table :data="tableData" border max-height="600">
                <el-table-column prop="fileName" align="center" label="文件名"> </el-table-column>
                <el-table-column prop="timeCreated" align="center" label="上传时间"> </el-table-column>
                <el-table-column prop="other.name" align="center" label="产品"> </el-table-column>
                <el-table-column prop="recordType" align="center" label="档案类别"> </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" plain @click="HandSee(scope.row, scope.$index)" style="margin-right: 12px">
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog title="文件信息" :visible.sync="seeDialogVisible" width="30%" :before-close="Cancel">
                <el-form :model="seeFrom">
                    <el-form-item label="文件名称">
                        <el-input :placeholder="seeFrom.fileName" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="文件类型">
                        <el-input :placeholder="seeFrom.fileType" disabled />
                    </el-form-item>
                    <el-form-item label="档案类别">
                        <el-input :placeholder="seeFrom.name" disabled />
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <el-input :placeholder="seeFrom.timeCreated" disabled />
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="Cancel">取 消</el-button>
                </span>
            </el-dialog>
            <el_pagination :total="total" :num="num" @page-limit="page_limit"></el_pagination>
        </div>
    </div>
</template>

<script>
import { fileDownload, fileList, fileRemove, productfileUpload } from '@/api/file';

import { documentList } from '@/api/document';
// import  download from "@/utils/download.js"

export default {
    name: 'Document',
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            pageSize: 10,
            seeDialogVisible: false,
            currentPage: 1,
            num: 0,
            total: 0,
            fileName: '',
            seeFrom: {},
            nowFilename: null,
            searchData:null
        };
    },
    computed: {
        baseApi() {
            return process.env.VUE_APP_BASE_API;
        }
    },
    watch: {
        nowFilename(newValue, oldValue) {
            console.log('新值', newValue);
            console.log('旧值', oldValue);
        }
    },
    created() {
        this.getFileList();
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        async search() {
            this.fileName = this.searchData
            this.getFileList()
           
        },

        //  查看
        async HandSee(row, $index) {
            let id = row.id;
            //  window.location.href=process.env.VUE_APP_BASE_API+`/archiveManagement/download?id=${row.id}`

            // console.log(process.env.VUE_APP_BASE_API)
            const res = await productfileUpload({ id });

            let url = window.URL.createObjectURL(new Blob([res]));
            // const url = new Blob([res])
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.download = row.fileName + row.fileType;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        Cancel() {
            this.seeDialogVisible = false;
        },
        //文档表
        async getFileList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            const res = await documentList({
                limit,
                page,
                fileName:this.fileName
            });

            if (res.code === 200) {
                //   console.log(res.data);
                this.tableData = res.data;
                this.total = res.total;
                this.num = parseInt(this.total / limit + 1);
            }
        },
        reset(searchForm) {

  
            this.fileName = null
            this.searchData = null
          
              this.getFileList();
        },

        async deleteFile(id) {
            const res = await fileRemove(id);
            if (res.code === 200) {
                this.$message.success('删除成功');
            } else {
                this.$message.error('删除失败');
            }
            await this.getFileList();
        },
        async downloadFile(id) {
            const res = await fileDownload(id);
            download(res, 'test');
        }
    }
};
</script>


<style lang="scss" scoped>
.document {
    width: 100%;
    height: 100%;
}

.button-gutter {
    margin: 20px 0;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.name_left {
    height: 100%;
    width: 500px;
}
.btn_right {
    height: 100%;
    width: 200px;
}
</style>